












































import { Component, Mixins } from 'vue-property-decorator'

import { CartViewMixin } from '../../../../shared/mixins/cartView.mixin'
import { FullscreenLoader } from '../../../../shared/molecules/Loader'
import { IToastMixin, ToastMixin } from '../../../../shared'
import { SidesLayout } from '../../../../shared/molecules/SidesLayout'

import { BaseCartMixin, IBaseCart } from '../../../shared/mixins/base-cart.mixin'

import { CartSummary } from '../../../organisms/CartSummary'
import { SingleCartItem } from '../../../organisms/SingleCartItem'
import { translateToCartItem } from '../../../molecules/CartItem/CartItem.helpers'
import { CartItemData } from '../../../../../contexts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<Basic>({
  name: 'Basic',
  components: { CartSummary, SidesLayout, SingleCartItem, FullscreenLoader },
  mounted (): void {
    const getFirstVariant = (item: CartItemData) => {
      return Object.values(item.product.variants)[0]
    }
    const getFirstVariantKey = (item: CartItemData) => {
      const key = Object.keys(item.product.variants)[0] ?? ''
      return (key !== '_') ? key : ''
    }

    if (this.cart) {
      this.eventBus.emit(
        'app:cart.view',
        {
          currency: this.cart.total.currency,
          value: this.cart.total.value,
          coupon: (this.cart.coupons[0]) ? this.cart.coupons[0].code : '',
          items: this.cart.items.map((item) => {
            const variant = getFirstVariant(item)
            const variantSlug = getFirstVariantKey(item)
            return {
              id: variant.sku,
              parent_id: item.product.sku,
              variant: variantSlug,
              name: variant.name,
              currency: this.cart?.total.currency,
              category: variant.attributes.mainCategory,
              brand: variant.attributes.brand,
              price: variant.price.finalPrice,
              quantity: item.quantity,
              url: this.siteService.getActiveSiteAddress() + `${item.product.urlPath}`,
              image: item.product.variants && (Array.isArray(variant.images) &&
                variant.images.length > 0)
                ? this.siteService.getActiveSiteAddress() +
                variant.images[0].url : ''
            }
          })
        }
      )
    }
  }
})
export class Basic extends Mixins<IToastMixin, IBaseCart, CartViewMixin>(
  ToastMixin,
  BaseCartMixin,
  CartViewMixin
) {
  public translateToCartItem = translateToCartItem
}

export default Basic
