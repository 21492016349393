










import { Component, Mixins } from 'vue-property-decorator'
import { StructureConfigurable } from '../../../../support/mixins'

import { FullscreenLoader } from '../../../shared/molecules/Loader'
import { IToastMixin, ToastMixin } from '../../../shared'
import { RecommendationAction } from '../../../products/contracts/repositories'
import { SidesLayout } from '../../../shared/molecules/SidesLayout'
import { SuggestedProductsMixin } from '../../../shared/mixins/suggestedProducts.mixin'

import { BaseCartMixin, IBaseCart } from '../../shared/mixins/base-cart.mixin'

import { CartSummary } from '../../organisms/CartSummary'
import { SingleCartItem } from '../../organisms/SingleCartItem'

import {
  CART_VIEW_CONFIG_MAP,
  CART_VIEW_KEY,
  cartViewComponent,
  CartViewConfig
} from './Cart.config'
import { VueConstructor } from 'vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<CartView>({
  name: 'CartView',
  components: { CartSummary, SidesLayout, SingleCartItem, FullscreenLoader },
  created () {
    this.config = this.getComponentConfig(CART_VIEW_KEY, { ...CART_VIEW_CONFIG_MAP })

    if (this.shouldLoadSuggestedProducts) {
      /**
       * @inheritDoc
       */
      this.loadSuggestedProducts(null, RecommendationAction.Recommendation)
    }
  }
})
export class CartView extends Mixins<IToastMixin, IBaseCart, StructureConfigurable, SuggestedProductsMixin>(ToastMixin, BaseCartMixin, StructureConfigurable, SuggestedProductsMixin) {
  protected config!: CartViewConfig

  /**
   * Render component for Cart View
   */
  public get cartComponentLayout (): VueConstructor {
    const layout = this.getConfigProperty<string>('layout')
    return cartViewComponent[layout]
  }

  /**
   * Determines whether cart should load suggested products list
   */
  public get shouldLoadSuggestedProducts (): boolean {
    return this.getConfigProperty<boolean>('shouldLoadSuggestedProducts')
  }
}

export default CartView
